<template>
  <div>
    <div class="loading-screen" id="loading-screen">
      <div class="loading-spinner"></div>
      <p class="loading-text">Loading...</p>
    </div>
    <div id="container" ref="container">
      <div id="tag" style="display: none">
        <!-- position:relative;约束子元素绝对定位参照点 -->
        <div style="position: relative; width: 400px; height: 322px; color: #fff">
          <!-- 图片绝对定位100%填充父元素，作为标签的背景 -->
          <img
            src="../assets/信息背景.png"
            alt=""
            style="width: 100%; position: absolute; left: 0px; top: 0px"
          />

          <!-- 名称、存储量、设备状态、等信息叠加到背景图上即可 -->
          <div style="position: absolute; left: 48px; top: 36px; font-size: 16px">
            <div style="font-size: 20px; font-weight: 400">
              <span id="name">烟感探测器</span>
            </div>
            <div style="margin-top: 30px">
              <span style="font-weight: 400; margin-left: 80px; font-size: 40px; color: #00ffff"
                >设备在线</span
              >
            </div>
            <div style="margin-top: 20px">
              <span style="color: #ccc; font-weight: 300">设备号</span
              ><span style="font-weight: 400; margin-left: 30px">1f-sb-yg3</span>
            </div>
            <div style="margin-top: 10px">
              <span style="color: #ccc; font-weight: 300">设备位置</span
              ><span style="font-weight: 400; margin-left: 30px">一楼展厅</span>
            </div>
          </div>
          <div style="position: absolute; left: 285px; top: 35px">
            <span style="color: #ffff00">异常</span>
          </div>

          <div style="position: absolute; left: 350px; top: 20px">
            <img
              id="close"
              ref="close"
              src="../assets/关闭.png"
              width="32"
              style="pointer-events: auto"
            />
          </div>
        </div>
      </div>
    </div>
    <div ref="statsRef" class="statsRef"></div>
    <el-button @click="choosefloor(2)" style="position: absolute; left: 500px">拆楼</el-button
    ><el-button @click="resetbuild()" style="position: absolute; left: 600px">还原</el-button>
    <el-button @click="manyou()" style="position: absolute; left: 700px" id="manyoubtn">{{
      manyoudstate ? '鸟瞰' : '漫游'
    }}</el-button>
    <el-button @click="locatequip()" style="position: absolute; left: 800px" id="manyoubtn"
      >定位设备</el-button
    >
    <el-button @click="removelabel()" style="position: absolute; left: 900px" id="manyoubtn"
      >删除标签</el-button
    >
  </div>
</template>

<script>
import * as THREE from 'three';
import * as dat from 'dat.gui';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
// 引入后处理扩展库EffectComposer.js
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
// 引入渲染器通道RenderPass
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
// 引入OutlinePass通道
import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass.js';
// 伽马校正后处理Shader
import { GammaCorrectionShader } from 'three/examples/jsm/shaders/GammaCorrectionShader.js';
// ShaderPass功能：使用后处理Shader创建后处理通道
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
// 引入CSS3渲染器CSS3DRenderer
import { CSS3DRenderer } from 'three/examples/jsm/renderers/CSS3DRenderer.js';
// 引入CSS2模型对象CSS2DObject
import { CSS3DSprite } from 'three/examples/jsm/renderers/CSS3DRenderer.js';
import Stat from 'three/examples/jsm/libs/stats.module.js';
import TWEEN from '@tweenjs/tween.js';
//引入指针锁控制器
import { PointerLockControls } from 'three/examples/jsm/controls/PointerLockControls';
export default {
  name: 'ThreeViewer',
  data() {
    //上次渲染时间
    this.prevTime = 0;
    //移动速度
    this.speed = 0.3;
    //是否有加速
    this.accelerated = false;
    //能否跳跃
    this.canJump = true;
    //前后左右
    this.forward = false;
    this.back = false;
    this.left = false;
    this.right = false;
    this.buildfloorinfo = [];
    this.equipinfo = [];
    this.gltfscene = null;
    //三维向量存储相机位置信息
    this.velocity = new THREE.Vector3();
    //射线 用来判断是否与物体相交
    this.pzraycaster = new THREE.Raycaster(new THREE.Vector3(), new THREE.Vector3(0, 2, 0), 0, 2);

    return {
      manyoudstate: false,
      disassemblystate: false,
      resetstate: false,
      disassemblyfloor: 1,
    };
  },
  mounted() {
    this.init();
    // 鼠标单击按钮，关闭HTML标签
    document.getElementById('close').addEventListener('click', this.removelabel);
    document.getElementById('manyoubtn').addEventListener('keydown', (event) => {
      if (event.code === 'Space' || event.code === 'Enter') {
        event.preventDefault();
      }
    });
  },
  beforeDestroy() {
    this.gui.destroy();
  },
  methods: {
    init() {
      const container = this.$refs.container;
      const width = container.clientWidth;
      const height = container.clientHeight;
      // 创建场景
      this.scene = new THREE.Scene();
      // 创建相机
      this.camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1200);
      this.camera.position.set(-63.151111945268084, 86.68678092277206, 102.2249093843876);
      this.gui = new dat.GUI();
      // 创建渲染器
      const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
      renderer.setSize(width, height);
      renderer.shadowMap.enabled = true;
      container.appendChild(renderer.domElement);

      // 创建后处理对象EffectComposer，WebGL渲染器作为参数
      const composer = new EffectComposer(renderer);
      // 1. 创建一个渲染器通道，场景和相机作为参数
      const renderPass = new RenderPass(this.scene, this.camera);
      // 设置renderPass通道
      composer.addPass(renderPass);

      //创建伽马校正通道
      const gammaPass = new ShaderPass(GammaCorrectionShader);
      composer.addPass(gammaPass);

      // 2. 创建OutlinePass通道
      const v2 = new THREE.Vector2(width, height);
      this.outlinePass = new OutlinePass(v2, this.scene, this.camera);
      this.outlinePass.visibleEdgeColor.set(0x00ffff);
      this.outlinePass.edgeThickness = 4;
      this.outlinePass.edgeStrength = 10;
      this.outlinePass.pulsePeriod = 2;
      composer.addPass(this.outlinePass);

      // 创建一个CSS3渲染器CSS3DRenderer
      const css3Renderer = new CSS3DRenderer();
      css3Renderer.setSize(width, height);
      // HTML标签<div id="tag"></div>外面父元素叠加到canvas画布上且重合
      css3Renderer.domElement.style.position = 'absolute';
      css3Renderer.domElement.style.top = '0px'; //具体值根据canvas画布位置来定
      //设置.pointerEvents=none，解决HTML元素标签对threejs canvas画布鼠标事件的遮挡
      css3Renderer.domElement.style.pointerEvents = 'none';
      container.appendChild(css3Renderer.domElement);
      window.addEventListener(
        'resize',
        () => {
          if (this.camera) {
            this.camera.aspect = window.innerWidth / window.innerHeight;
          }
          this.camera.updateProjectionMatrix();
          renderer.setSize(window.innerWidth, window.innerHeight);
          css3Renderer.setSize(window.innerWidth, window.innerHeight);
        },
        false
      );
      const tagdiv = document.getElementById('tag');
      tagdiv.style.top = '2px'; //指示线端点放在标注点附近
      // HTML元素转化为threejs的CSS2模型对象
      this.tag = new CSS3DSprite(tagdiv);

      const ambientLight = new THREE.AmbientLight(0xffffff, 1);
      const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
      directionalLight.position.set(257.29, 335, 906.41);
      // 设置光源的投影
      directionalLight.castShadow = true;
      directionalLight.shadow.mapSize.width = 1024; // 阴影贴图的宽度
      directionalLight.shadow.mapSize.height = 1024; // 阴影贴图的高度
      directionalLight.shadow.camera.top = 800;
      directionalLight.shadow.bottom = -800;
      directionalLight.shadow.left = -800;
      directionalLight.shadow.right = 800;
      directionalLight.shadow.near = 0.1; // 阴影相机近裁剪面
      directionalLight.shadow.far = 10000; // 阴影相机远裁剪面
      this.scene.add(ambientLight, directionalLight);
      const sunLightHelper = new THREE.DirectionalLightHelper(directionalLight, 50); // 创建一个辅助器，并将其与光源绑定
      this.scene.add(sunLightHelper); // 将辅助器添加到场景中

      this.tweens = [];
      // 添加OrbitControls控制器
      this.controls = new OrbitControls(this.camera, container);
      this.controls.enableDamping = false;
      //  this.controls.dampingFactor = 0.1;
      const center = new THREE.Vector3(0, 0, 0); // 圆心点
      const radius = 300; // 半径
      this.controls.target.copy(center); // 设置控制器中心点为圆心
      this.controls.addEventListener('change', () => {
        // console.log(this.camera.position);
        //  render.bind(this);
        const currentPosition = this.camera.position.clone();
        const direction = currentPosition.sub(center);
        const distance = direction.length();
        let originalX, originalZ; // 存储相机移动到y轴负数时的x和z坐标
        // 如果相机位置超出范围，将相机位置重置为范围内最近的点
        // 如果相机位置超出范围，将相机位置重置为范围内最近的点
        if (distance > radius || this.camera.position.y < 0) {
          if (this.camera.position.y < 1) {
            originalX = this.camera.position.x;
            originalZ = this.camera.position.z;
          }
          this.camera.position.copy(center).add(direction.normalize().multiplyScalar(radius));
          this.camera.position.y = Math.max(this.camera.position.y, 1); // 将y坐标限制为0或正数
          if (this.camera.position.y === 1 && originalX !== undefined && originalZ !== undefined) {
            this.camera.position.x = originalX;
            this.camera.position.z = originalZ;
          }
          this.controls.target.copy(center);
        }
      });
      //创建指针锁控制器;
      this.pointerlockcontrols = new PointerLockControls(this.camera, renderer.domElement);
      // 创建帧率监测器
      var stats = new Stat();
      this.$refs.statsRef.append(stats.dom);
      stats.dom.style.top = '60px';
      let loadedCount = 0;
      const gltfUrls = [
        'Model/map/dt.gltf',
        'Model/groud/lu.gltf',
        'Model/cj3.1p2/cj3.1p2.gltf',
        'Model/zt3.4/zt3.4.glb',
      ];
      const loadGLTFmodel = (url) => {
        loadedCount++;
        console.log('加载模型', loadedCount);
        const loader = new GLTFLoader();
        loader.load(url, (gltf) => {
          // console.log(gltf);
          if (loadedCount < 3) {
            // gltf.scene.traverse((object) => {
            //   if (object.isMesh) {
            //     // 修改模型的材质
            //     // console.log();
            //     // object.castShadow = true;
            //     object.receiveShadow = true;
            //   }
            // });
            // gltf.scene.receiveShadow = true;
          } else if (loadedCount == 3) {
            // gltf.scene.traverse((object) => {
            //   if (object.isMesh) {
            //     // 修改模型的材质
            //     // console.log();
            //     object.castShadow = true;
            //     // object.receiveShadow = true;
            //   }
            // });
            // this.gui.add(gltf.scene.position, 'y', -100, 100, 0.1).name('py');
            // gltf.scene.receiveShadow = true;
          } else {
            const glassMaterial = new THREE.MeshStandardMaterial({
              color: 0x86c9e6, // 玻璃的颜色
              metalness: 0.5, // 金属度
              roughness: 0.1, // 粗糙度
              transparent: true, // 开启透明
              opacity: 0.5, // 透明度
              envMapIntensity: 1, // 环境贴图强度
            });
            gltf.scene.traverse((object) => {
              if (object.isMesh) {
                // 修改模型的材质
                // console.log();
                // object.castShadow = true;
                // object.receiveShadow = true;
                // object.material.transparent = true;
                // object.material.alphaTest = 0.5;
                // object.material.blending = THREE.NormalBlending;
                if (object.material.name == 'glass') {
                  object.material = glassMaterial;
                }
              }
            });
            for (let i = 1; i <= 16; i++) {
              this.buildfloorinfo.push(gltf.scene.getObjectByName(`${i}f-group`));
            }
            this.equipinfo.push(gltf.scene.getObjectByName('1f-sb-yg3'));
            var folder5 = this.gui.addFolder('group的位置');
            folder5.add(gltf.scene.position, 'x', -100, 100, 1).name('groupx');
            folder5.add(gltf.scene.position, 'y', -100, 100, 0.1).name('groupy');
            folder5.add(gltf.scene.position, 'z', -100, 100, 1).name('groupz');
            console.log(this.buildfloorinfo);
          }
          this.gltfscene = gltf.scene;
          this.scene.add(gltf.scene);
          // 检查是否还有剩余的GLTF需要加载
          if (loadedCount < gltfUrls.length) {
            const nextUrl = gltfUrls[loadedCount];
            loadGLTFmodel(nextUrl); // 递归加载下一个GLTF
          } else {
            // 所有GLTF加载完成
            console.log('All GLTF models loaded.');
            document.getElementById('loading-screen').style.display = 'none';
          }
        });
      };
      loadGLTFmodel(gltfUrls[loadedCount]);

      // 渲染函数
      const render = () => {
        let time = performance.now(); //本次渲染时间
        let delta = (time - this.prevTime) / 1000; //时间差 (s)
        //移动逻辑 向前向后移动: +1 || -1 向左向右移动: -1 || +1
        //跳跃逻辑 执行每一帧动画时控制器都下落 当控制器高度小于起始高度时 还原按下空格时给一个上升的y高度  每一帧动画都会递减
        if (this.pointerlockcontrols.isLocked) {
          this.velocity.x = 0;
          this.velocity.z = 0;
          this.velocity.y -= 200 * delta; // 下降速度
          //获取相机位置
          let position = this.pointerlockcontrols.getObject().position;
          //设置射线原点
          this.pzraycaster.ray.origin.copy(position);
          this.pzraycaster.ray.origin.y -= 4;
          //检测所有相交的物体
          let intersects = this.pzraycaster.intersectObjects(this.gltfscene.children, false);
          //自带的方法判断需要过滤
          if (intersects.length) {
            this.velocity.y = Math.max(0, this.velocity.y);
            this.canJump = true;
          }

          if (this.forward || this.back) {
            //向前才可加速
            this.velocity.z =
              (Number(this.forward) - Number(this.back)) * this.speed +
              (this.forward ? Number(this.accelerated) * 0.5 : 0);
          }
          if (this.left || this.right) {
            this.velocity.x =
              (Number(this.right) - Number(this.left)) * this.speed +
              Number(this.accelerated) * 0.5;
          }

          //四个方位是否产生碰撞
          let leftCollide = false;
          let rightCollide = false;
          let forwardCollide = false;
          let backCollide = false;
          //碰撞检测 collide check
          if (this.forward) forwardCollide = this.collideCheck(0);
          if (this.back) backCollide = this.collideCheck(180);
          if (this.left) leftCollide = this.collideCheck(90);
          if (this.right) rightCollide = this.collideCheck(270);

          //右侧有障碍物时向右移动 置零
          if ((this.right && rightCollide) || (this.left && leftCollide)) {
            this.velocity.x = 0;
          }
          //前方有障碍物时向前移动 置零
          if ((this.forward && forwardCollide) || (this.back && backCollide)) {
            this.velocity.z = 0;
          }
          //设置控制器移动
          this.pointerlockcontrols.moveRight(this.velocity.x);
          this.pointerlockcontrols.moveForward(this.velocity.z);
          this.pointerlockcontrols.getObject().position.y += this.velocity.y * delta;
          //还原起始高度
          if (this.pointerlockcontrols.getObject().position.y < 2) {
            this.velocity.y = 2;
            this.pointerlockcontrols.getObject().position.y = 2;
            this.canJump = true;
          }
        } else {
          this.controls.update();
        }
        renderer.render(this.scene, this.camera);
        css3Renderer.render(this.scene, this.camera);
        composer.render();
        for (let i = this.tweens.length - 1; i >= 0; i--) {
          const tween = this.tweens[i];
          if (tween && tween.isPlaying) {
            TWEEN.update();
          } else {
            this.tweens.splice(i, 1); // 从数组中移除已完成的Tween实例
          }
        }
        stats.update();
        requestAnimationFrame(render);
      };

      // 调用渲染函数
      render();
    },
    async locatequip() {
      await this.choosefloor(0);
      this.moveCameraTo(
        this.equipinfo[0].position.x,
        this.equipinfo[0].position.y,
        this.equipinfo[0].position.z + 35,
        1000
      );
      this.outlinePass.selectedObjects = [this.equipinfo[0]];
      this.tag.scale.set(0.01, 0.01, 1);
      this.equipinfo[0].add(this.tag);
    },
    removelabel() {
      console.log('removelabel');
      if (this.equipinfo[0]) {
        //把原来选中模型对应的标签和发光描边隐藏
        this.outlinePass.selectedObjects = []; //无发光描边
        this.equipinfo[0].remove(this.tag); //从场景移除
        console.log('移除', this.tag, this.equipinfo[0]);
      }
    },
    choosefloor(index) {
      return new Promise((resolve) => {
        // 异步操作
        this.disassemblyfloor = index;
        this.disassemblystate = !this.disassemblystate;
        // 创建一个Tween动画，让每层楼逐渐上升到指定的高度
        if (this.disassemblystate && !this.resetstate) {
          let delay = 0;
          for (let i = 15; i > 0; i--) {
            const floorTween = new TWEEN.Tween(this.buildfloorinfo[i].position)
              .to({ y: this.buildfloorinfo[i].position.y + 5 * i }, 100)
              .easing(TWEEN.Easing.Quadratic.InOut)
              .delay(delay)
              .onComplete(() => {
                this.removeTweenFromLoop(floorTween);
                // 在Tween完成后，再开始下一层的Tween动画
                if (i > 1) {
                  const nextTween = new TWEEN.Tween(this.buildfloorinfo[i - 1].position)
                    .to({ y: this.buildfloorinfo[i - 1].position.y + 5 * (i - 1) }, 100)
                    .easing(TWEEN.Easing.Quadratic.InOut)
                    .onComplete(() => {
                      // 动画完成时的回调函数
                      // 从循环中移除Tween实例
                      this.removeTweenFromLoop(nextTween);
                    })
                    .start();
                  this.tweens.push(nextTween);
                } else {
                  const moveoutTween = new TWEEN.Tween(this.buildfloorinfo[index].position)
                    .to({ z: this.buildfloorinfo[index].position.z + 35 }, 100)
                    .easing(TWEEN.Easing.Quadratic.InOut)
                    .onComplete(() => {
                      this.removeTweenFromLoop(moveoutTween);
                      this.disassemblystate = false;
                    })
                    .start();
                  this.tweens.push(moveoutTween);
                }
              })
              .start();
            this.tweens.push(floorTween);
            delay += 100;
          }
        }
        setTimeout(() => {
          console.log('tween执行完毕');
          resolve(); // 表示异步操作完成
        }, 2000); // 假设tween操作需要2秒完成
      });
    },
    removeTweenFromLoop(tween) {
      if (tween) {
        tween.stop();
        tween.onComplete(null); // 清除完成时的回调函数
      }
    },
    resetbuild() {
      this.resetstate = !this.resetstate;
      // 创建一个Tween动画，让每层楼逐渐上升到指定的高度
      if (this.resetstate && !this.disassemblystate) {
        this.outlinePass.selectedObjects = [];
        let delay = 0;
        for (let i = 1; i < 16; i++) {
          const moveoutTween = new TWEEN.Tween(this.buildfloorinfo[this.disassemblyfloor].position)
            .to({ z: this.buildfloorinfo[this.disassemblyfloor].position.z - 35 }, 100)
            .easing(TWEEN.Easing.Quadratic.InOut)
            .onComplete(() => {
              this.removeTweenFromLoop(moveoutTween);
              const floorTween = new TWEEN.Tween(this.buildfloorinfo[i].position)
                .to({ y: this.buildfloorinfo[i].position.y - 5 * i }, 100)
                .easing(TWEEN.Easing.Quadratic.InOut)
                .delay(delay)
                .onComplete(() => {
                  this.removeTweenFromLoop(floorTween);
                  // 在Tween完成后，再开始下一层的Tween动画
                  if (i < 15) {
                    const nextTween = new TWEEN.Tween(this.buildfloorinfo[i + 1].position)
                      .to({ y: this.buildfloorinfo[i + 1].position.y - 5 * (i + 1) }, 100)
                      .easing(TWEEN.Easing.Quadratic.InOut)
                      .onComplete(() => {
                        // 动画完成时的回调函数
                        this.removeTweenFromLoop(nextTween);
                      })
                      .start();
                    this.tweens.push(nextTween);
                  } else {
                    this.moveCameraTo(
                      this.buildfloorinfo[15].position.x,
                      this.buildfloorinfo[15].position.y,
                      this.buildfloorinfo[15].position.z,
                      1000
                    );
                    this.resetstate = false;
                  }
                })
                .start();
              this.tweens.push(floorTween);
              delay += 100;
            })
            .start();
          this.tweens.push(moveoutTween);
        }
      }
    },
    moveCameraTo(x, y, z, duration) {
      if ((x != null) & (y != null) & (z != null) & (duration != null)) {
        let targetPosition = new THREE.Vector3();
        targetPosition.set(x + 20, y + 20, z + 20);
        const distance = this.camera.position.distanceTo(targetPosition);
        const moveCameratween = new TWEEN.Tween(this.camera.position)
          .to(targetPosition, duration)
          .easing(TWEEN.Easing.Quadratic.InOut)
          .onUpdate(() => {
            const distanceToTarget = this.camera.position.distanceTo(targetPosition);
            const fov = this.camera.fov + (distance - distanceToTarget) / 10;
            this.camera.fov = THREE.MathUtils.clamp(fov, 30, 75);
            this.camera.lookAt(x, y, z);
            this.camera.updateProjectionMatrix();
          })
          .onComplete(() => {
            // this.controls.update();
            this.removeTweenFromLoop(moveCameratween);
          })
          .start();
        this.tweens.push(moveCameratween);
      }
    },
    manyou() {
      this.manyoudstate = !this.manyoudstate;
      if (this.manyoudstate) {
        // this.moveCameraTo(1, 1, 1, 1000);
        this.controls.enabled = false;
        this.scene.add(this.pointerlockcontrols.getObject());
        this.pointerlockcontrols.lock();
        document.addEventListener('keyup', this.keyUp, false);
        document.addEventListener('keydown', this.keyDown, false);
      } else {
        this.pointerlockcontrols.unlock();
        this.scene.remove(this.pointerlockcontrols.getObject());
        this.controls.enabled = true;
        document.removeEventListener('keyup', this.keyUp, false);
        document.removeEventListener('keydown', this.keyDown, false);
      }
    },
    keyUp(e) {
      switch (e.code) {
        case 'KeyW': //前
        case 'ArrowUp':
          this.forward = false;
          break;
        case 'KeyA': //左
        case 'ArrowLeft':
          this.left = false;
          break;
        case 'KeyD': //右
        case 'ArrowRight':
          this.right = false;
          break;
        case 'KeyS': //后
        case 'ArrowDown':
          this.back = false;
          break;
        case 'ShiftLeft': // 加速
          this.accelerated = false;
          break;
      }
    },
    keyDown(e) {
      switch (e.code) {
        case 'KeyW': //前
        case 'ArrowUp':
          this.forward = true;
          break;
        case 'KeyA': //左
        case 'ArrowLeft':
          this.left = true;
          break;
        case 'KeyD': //右
        case 'ArrowRight':
          this.right = true;
          break;
        case 'KeyS': //后
        case 'ArrowDown':
          this.back = true;
          break;
        case 'ShiftLeft': //加速
          this.accelerated = true;
          break;
        case 'Space': // 跳
          if (this.canJump) {
            this.velocity.y += 30;
            console.log('tiao');
          }
          this.canJump = false;
          break;
      }
    },
    //根据传入角度判断附近是否有障碍物
    //移动是根据按键决定的 在按下左键的时候进行左侧检测 右侧就右侧检测 利用射线判断有没有与物体相交 如果撞到物体上了就阻止这一侧的移动
    collideCheck(angle) {
      let rotationMatrix = new THREE.Matrix4();
      rotationMatrix.makeRotationY((angle * Math.PI) / 180);
      const cameraDirection = this.pointerlockcontrols
        .getDirection(new THREE.Vector3(0, 0, 0))
        .clone();
      cameraDirection.applyMatrix4(rotationMatrix);
      const raycaster = new THREE.Raycaster(
        this.pointerlockcontrols.getObject().position.clone(),
        cameraDirection,
        0,
        2
      );
      raycaster.ray.origin.y -= 4;
      const intersections = raycaster.intersectObjects(this.gltfscene.children, true);
      return intersections.length;
    },
  },
};
</script>

<style scoped>
#container {
  width: 100%;
  height: 100%;
  position: absolute;
}
#close:hover {
  cursor: pointer;
}
.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 3px solid #fff;
  border-top-color: #00ffea;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-text {
  color: #fff;
  margin-top: 20px;
  font-size: 16px;
}
</style>
